<template>
    <div>
         <div class="uk-alert-warning" uk-alert>
            <p>{{'Message_changePas' | localize}}</p>
        </div>
        <form class="toggle-class" @submit.prevent="change">
            <fieldset class="uk-fieldset">
                <div class="uk-margin-small">
                    <div class="uk-inline uk-width-1-1">
                        <input class="uk-input uk-width-1-2 uk-align-center " id='password' v-model="password" required :placeholder="localize('Password')" type="password">
                    </div>
                </div>
                <div>
                    <button class="uk-button uk-button-primary uk-border-pill uk-width-1-2 uk-align-center">{{'Change' | localize}}</button>
                </div>
            </fieldset>
        </form>
    </div>
</template>

<script>
import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
import store from '@/store'
export default {
    metaInfo:{
        title: localizeFilter('TitleSetPas')
    },
    data: () => ({
        password: '',
        login: '',
        key: ''
    }),
    methods:{
        change(){
              this.$Progress.start()
              axios.get('/users.php', 
                {
                    params: 
                    {
                        login: this.$route.params['login'], 
                        key: this.$route.params['key'], 
                        password:this.password,
                        type: 2
                    }
                })
                .then(response => {
                   if(response.data.answer == 1){
                        Journal(this.$route.params['login'], 'Пользователь изменил первичный пароль')
                        this.$cookies.set("changed", true, Infinity)
                        localStorage.setItem("login", this.$route.params['login']);

                        var cotirovki = {
                                'defPt' : response.data.cotirovki['Pt'],
                                'defPd' : response.data.cotirovki['Pd'],
                                'defRh' : response.data.cotirovki['Rh'],
                                'usd' : response.data.cotirovki['usd'],
                                //'aud' : response.data.cotirovki['aud'],
                                'date' : response.data.cotirovki['date']
                        }
                        localStorage.setItem('cotirovki', JSON.stringify(cotirovki))

                        var date = response.data.cotirovki['date'].split('-')
                        store.state.defPt = response.data.cotirovki['Pt']
                        store.state.defPd =response.data.cotirovki['Pd']
                        store.state.defRh = response.data.cotirovki['Rh']
                        store.state.defusd = response.data.cotirovki['usd']
                        if($cookies.get('aud') === null){
                            axios({
                                method: 'get',
                                baseURL: '',
                                url: 'https://openexchangerates.org/api/latest.json?app_id=76c1324c6345452c81b7a270939a68e4'

                            })
                            .then(response => {
                                store.state.defaud = response.data.rates.AUD.toFixed(2)
                                $cookies.set('aud', store.state.defaud, '3h')
                            })
                            .catch(error => {
                                store.state.defaud = 1.2967333.toFixed(2)
                                $cookies.set('aud', store.state.defaud, '3h')
                                console.log(error)
                                UIkit.notification({message: 'Error while get USD-AUD exchange, please contact system administrator', status:'danger'})
                            })
                        }
                        else{
                            store.state.defaud = $cookies.get('aud')
                            localStorage.setItem('aud', store.state.defaud)
                        }
                        store.state.date = date[2] + '-' + date[1] + '-' + date[0].slice(-2)
                        store.state.price = localStorage.getItem('price') || 'rub'

                        this.$router.push('/')
                   }
                   this.$Progress.finish()
                })
                .catch(error =>{
                    console.log(error)
                    this.$Progress.fail()
                    this.$data.answer = -10
                })
        },
        localize(key){
            return localizeFilter(key)
        },
        localizeFilter
    },
    beforeMount(){
        this.$cookies.remove("loggedIn")
        this.$cookies.remove('role')
    }

}
</script>